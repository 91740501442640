<template>
  <div class="modal fade " ref="modalConfirmation" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ local_config.title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-if="showInternalForm"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="form-group" v-for="(field, index) in local_config.form_fields" :key="index"
              v-if="showInternalForm">

              <label :for="field.id"  style="font-weight: bold;margin-top:10px;">{{ field.label }}</label>

              <input v-if="field.type === 'label'" :type="text" :disabled="true" :readonly="true" :id="field.id"
                :placeholder="field.placeholder" class="form-control" v-model="field.value" />

              <input v-if="field.type === 'text' || field.type === 'password'" :type="field.type" :id="field.id"
                :placeholder="field.placeholder" class="form-control" v-model="field.value" />

                <input v-if="field.type === 'password_with_policy'" type="password" :id="field.id" :placeholder="field.placeholder" class="form-control" v-model="field.value" @input="validatePassword(field)" />
                <span v-if="field.error && field.type === 'password_with_policy'" class="error-message">{{ field.error }}</span>

                <input v-if="field.type === 'password_with_policy_text'" type="text" :id="field.id" :placeholder="field.placeholder" class="form-control" v-model="field.value" @input="validatePassword(field)" />
                <span v-if="field.error && field.type === 'password_with_policy_text'" class="error-message">{{ field.error }}</span>

                <input v-if="field.type === 'password_repeat'" type="password" :id="field.id" :placeholder="field.placeholder" class="form-control" v-model="field.value" @input="validateRepeat(field)" />
                <span v-if="field.error && field.type === 'password_repeat'" class="error-message">{{ field.error }}</span>

              <select v-else-if="field.type === 'select'" :id="field.id" class="form-select" v-model="field.value">
                <option v-for="(option, index) in field.select_options" :key="index" :value="option.value">{{ option.label
                }}</option>
              </select>

            </div>

            <div v-if="extraMessage?.length > 0" v-html="extraMessage" style="margin-top:20px;">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-for="b in local_config.buttons" type="button" :key="b.label"
            :data-cy="b.cy || 'btn_' + b.label"
            :class="'btn custom_modal_buttons ' + b.class" :style="b.style||''"
            :disabled="b.disabled"
            @click.prevent="onAction(b.action)">{{ b.label }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinLayoutComponents from '@/mixins/layout_components';
import { Modal } from 'bootstrap';
import Dictionary from '@/mixins/dictionary';

export default {
  name: 'user-form-modal',
  data() {
    return {
      api_name: 'user-form',
      name: 'user-form-modal',
      dict_prefix: 'generic_form_modal',
      modalElem: null,
      show_internal_form: true,
      extra_message: '',
      modal_stated: false,
      local_config: {
        dialog_class: '',
        title: '...',
        buttons: [],
        form_fields: [],
      },
    };
  },

  mixins: [mixinLayoutComponents, Dictionary],
  components: {

  },
  computed: {
    extraMessage: {
      get() {
        return this.extra_message;
      },
      set(v) {
        this.extra_message = v;
      },
    },
    showInternalForm: {
      get() {
        return this.show_internal_form;
      },
      set(v) {
        this.show_internal_form = v;
      },
    },
  },
  methods: {
    validatePassword(field) {
      const password = field.value;
      if (password.length < 12) {
        field.error = this._d('error_user_password_min_size', 'パスワードは12文字以上でなければなりません。', 'An error message informing the user that the password must be at least 12 characters long.');
        return;
      }

      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasDigit = /\d/.test(password);
      const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
      const validConditions = [hasUpperCase, hasLowerCase, hasDigit, hasSymbol].filter(Boolean).length;
      if (validConditions < 3) {
        field.error = this._d('error_user_password_policy_rules', 'パスワードには、次のうち少なくとも3つを含める必要があります: 大文字、小文字、数字、記号。', 'An error message informing the user that the password must contain at least 3 of the following: uppercase, lowercase, number, symbol.');
      } else {
        field.error = '';
      }
    },
    validateRepeat(field) {
      const password = field.value;
      const passwordField = this.local_config.form_fields.find((f) => f.type === 'password_with_policy');
      if (passwordField.value !== password) {
        field.error = this._d('error_password_repeat_not_same', 'パスワードが一致しません。', 'An error message informing the user that the passwords do not match.');
      } else {
        field.error = '';
      }
    },
    showModal(config) {
      this.local_config.title = config?.title || '...';
      this.local_config.buttons = config?.buttons || [];
      this.local_config.dialog_class = config?.dialog_class || '';
      this.local_config.form_fields = config?.form_fields || [];
      console.log('TEST DEBUG 240203 showModal: ', { local_config: this.local_config });

      this.modalElem.show();
    },
    showForm(show_form, message = '') {
      this.showInternalForm = show_form;
      this.extraMessage = message;
    },
    onAction(action) {
      // this.modalElem.hide();
      action();
    },
  },
  mounted() {
    this.modal_stated = true;
    this.modalElem = new Modal(this.$refs.modalConfirmation);
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() { },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped>
@media (max-width: 550px) {
  .custom_modal_buttons {
    margin: 8px 0px;
    width: 100%
  }
}
.error-message {
  color: red;
  font-size: 0.9em;
}
</style>
