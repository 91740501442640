<template >
  <div class="row" style="display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0;">
    <div class="col-6">
      <div style="display: flex; justify-content: center; align-items: center;">
          <img src="/img/logo_v2.png" alt="e-attach"  height="270" />
      </div>
      <form >
        <div style="margin-top:20px" v-show="show_login_form">
          <input class="form_item form-control" data-cy="username" type="text" v-model="user_name_value" :placeholder="_d('username', 'ユーザー名', 'username to login')" autocomplete="username"  /><br>
          <input class="form_item form-control" data-cy="password" type="password"  v-model="password_value" :placeholder="_d('password', 'パスワード', 'password to login')" autocomplete="current-password"  /><br>
          <button class="form_item btn btn-primary" data-cy="login_btn" type="submit" style="" @click.prevent="onLogin" v-html="_d('log_in_button', 'ログイン', 'button to login')" ></button>
        </div>
        <div style="margin-top:20px; display: flex; justify-content: center; align-items: center; " v-show="show_error_message">
          <div>
            <span v-html="error_message"></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Dictionary from '@/mixins/dictionary';

export default {
  data() {
    return {
      dict_prefix: 'login',
      user_name_value: '',
      password_value: '',
      show_login_form: true,
      show_error_message: false,
      error_message: '',
    };
  },
  routes: [{
    path: '/login', name: 'Login', component: this, meta: { requiresAuth: false },
    // path: '/expired', name: 'Login', component: this, meta: { requiresAuth: false },
  }],
  mixins: [Dictionary],

  methods: {
    onLogin() {
      const body = {
        user_name: this.user_name_value,
        password: this.password_value,
      };

      this.$ajax.post('/user/login/', body, (err, data) => {
        console.log({ err, data, body });
        this.show_login_form = true;
        this.show_error_message = false;

        if (err) {
          this.$notify({
            duration: 30000,
            type: 'error',
            title: this._d('unexpected_error_popup_title', '予期しないエラー', 'At login page, a popup for an unexpected error title.'),
            text: err,
          });
        }
        if (data.user_is_blocked === true) {
          this.show_login_form = false;
          this.show_error_message = true;
          this.error_message = 'Your user account is blocked, contact your administrator';
          this.$notify({
            duration: 10000,
            type: 'error',
            title: 'Access blocked', // FIXME: Need to be translated.
            text: this.error_message, // FIXME: Need to be translated.
          });
          return;
        }
        if (data.tenant_is_blocked === true) {
          this.show_login_form = false;
          this.show_error_message = true;
          this.error_message = 'Your account is blocked.';
          this.$notify({
            type: 'error',
            title: 'Access blocked', // FIXME: Need to be translated.
            text: this.error_message, // FIXME: Need to be translated.
          });

          return;
        }

        if (data.logged === true) {
          localStorage.setItem('tenant_id', data.tenant_id);
          localStorage.setItem('display_name', data.display_name);
          localStorage.setItem('user_name', data.user_name);
          localStorage.setItem('user_level', data.user_level);
          this.$router.push('/');
          this.$notify({
            type: 'success',
            title: this._d('popup_success_login_title', 'Login', 'popup for success login title'),
            text: this._d('popup_success_login_text', 'Success', 'popup for success login text'),
          });
        } else {
          this.$notify({
            type: 'error',
            title: this._d('popup_fail_login_title', 'Login', 'popup for fail login title'),
            text: this._d('popup_fail_login_text', 'Invalid username or password', 'popup for fail login text'),
          });
        }
      });
    },
  },

  components: {},
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  mounted() {
    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped>
.btn-primary {
    background-color: #6666FF; /* Primary color */
    border-color: #5a62d2; /* Slightly darker shade for the border */
}

.btn-primary:hover {
    background-color: #6268e0; /* Slightly darker shade for hover */
    border-color: #4f58c2; /* Optionally, you could also darken the border color slightly on hover */
}
.form_item{
  display: block;
  margin: 0 auto;
  width:300px;
}

</style>
