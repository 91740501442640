<template>
  <div style="margin-top: 20px;width:100%;display: inline-block;">
      <button @click.prevent="onNew" class="btn btn-primary" v-if="use_add_new" data-cy="add_new" >+</button>
      <input type="text" class="form-control" style="width:200px;float: right;"
      v-if="use_search"
      data-cy="search_field"
        @input="localSearchControlChangedDatasource" placeholder="検索" aria-label="検索"
        aria-describedby="basic-addon1" />
    </div>
    <ag-grid-vue style="width: 100%; height: 325px; margin-top: 7px;" class="ag-theme-bootstrap"
      @rowClicked="onRowClicked"
      :gridOptions="gridOptions">
    </ag-grid-vue>
</template>
<script>
import agListController from '@/mixins/ag-list-controller';
import _ from 'lodash';
import datasource from '@/mixins/datasource';

export default {
  data() {
    return {
      name: 'ag-table',
      api_name: this.$props.apiName,
      // detail_page: 'attach-form',
      loaded_data: [],
      is_loaded: false,
      selected_id: null,
    };
  },
  computed: {
    use_search: {
      get() {
        return this.$props.useSearch;
      },
      set(_v) {
        console.log('TEST DEBUG 240218 (57 at ag-table.vue)[14:12]: IT IS READY ONLY', { });
      },
    },
    use_add_new: {
      get() {
        return this.$props.useAddNew;
      },
      set(_v) {
        console.log('TEST DEBUG 240218 (57 at ag-table.vue)[14:12]: IT IS READY ONLY', { });
      },
    },
    selectedId: {
      get() {
        return this.selected_id;
      },
      set(v) {
        this.selected_id = v;
      },
    },
    fieldList() {
      return this.$props.allFields;
    },
    tabFieldList() {
      return this.$props.tabFields || this.$props.allFields;
    },
    isLoaded: {
      get() {
        return this.is_loaded;
      },
      set(v) {
        this.is_loaded = v;
      },
    },
    loadedData: {
      get() {
        return this.loaded_data;
      },
      set(v) {
        this.loaded_data = v;
      },
    },
  },
  mixins: [agListController, datasource],
  components: {},
  methods: {
    reloadData() {
      console.log('TEST DEBUG 240219 (80 at ag-table.vue)[14:48]: chegou aqui', { });
      this.gridOptions.api.purgeInfiniteCache();
      this.countRows('AgTableReloadData');
    },
    onNew(ev) {
      this.$emit('onAddNew', ev);
    },
    onRowClicked(ev) {
      this.$emit('onRowClicked', ev);
    },
    onRowSelected(ev) {
      this.$emit('onRowSelected', ev);
    },
    localSearchControlChangedDatasource(ev) {
      this.searchByOneDatasource({ by_one: true, filter_by: 'search', one_value: ev.target.value });
    },

    createColumnDefs() {
      // TODO: If need, customize it more.
      return this.commonCreateColumnDefs({ show_details: false, show_checkbox: false, force_sort: this.$props.apiForceSortOptions });
    },
    onOpenAttach(attach_id) {
      console.log('TEST DEBUG 231028 (84 at history.vue)[14:59]: ', { attach_id });
    },
  },
  props: {
    allFields: {
      type: Object,
      required: true,
    },
    tabFields: {
      type: Object,
      required: true,
    },
    apiName: {
      type: String,
      required: true,
    },
    useSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    useAddNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    apiRequestOptions: {
      type: Object,
      required: true,
    },
    apiForceSortOptions: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  emits: ['onRowSelected', 'onRowClicked', 'onAddNew'],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    // console.log("beforeMount",{allFields:this.$props.allFields});
    // console.log("beforeMount",{tabFields:this.$props.tabFields});
    // console.log("beforeMount",{apiName:this.$props.apiName});
    // console.log("beforeMount",{searchFieldName:this.$props.searchFieldName});
    // console.log("beforeMount",{useSearch:this.$props.useSearch});
    // console.log("beforeMount",{useAddNew:this.$props.useAddNew});
    // console.log("beforeMount",{apiRequestOptions:this.$props.apiRequestOptions});
    // console.log('TEST DEBUG 240218 (177 at ag-table.vue)[14:45]: ', { opt:this.$props.apiRequestOptions });
    this.api_request_options = this.$props.apiRequestOptions || {};
    this.gridOptions = _.extend(this.commonGridOptions, {});
    this.initDatasource({
      loaded_cb: (_data) => {
        this.gridOptions.api.sizeColumnsToFit();
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 1000);
      },
    });
  },
  mounted() {
    this.gridOptions.api.purgeInfiniteCache();
    this.gridOptions.api.sizeColumnsToFit();
    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style >

.btn-primary {
  background-color: #6666FF;
  /* Primary color */
  border-color: #5a62d2;
  /* Slightly darker shade for the border */
}

.btn-primary:hover {
  background-color: #6268e0;
  /* Slightly darker shade for hover */
  border-color: #4f58c2;
  /* Optionally, you could also darken the border color slightly on hover */
}

.inactive_tab {
  color: #6666FF;
}

a.attach_link {
  color: #6666FF;
  font-size: 16px;
  ;
}

.ag-header-cell-text {
  font-size: 16px !important;
  font-weight: bold !important;
}

.ag-row {
  font-size: 16px !important;
  height: 35px !important;
  color: #6666FF !important;
}

.ag-cell {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.ag-theme-bootstrap .ag-ltr .ag-cell {
  border: none !important;
}

.ag-row:hover {
  background-color: #6666FF !important;
  color: white !important;

}

.ag-row-selected {
  background-color: #6666FF !important;
  color: white !important;
}

.ag-theme-bootstrap {
  border: 1px solid #ccc !important;
}

.ag-header-cell:not(:first-child) {
  border-left: 1px solid #ddd !important;
}</style>
