<template>
  <MainLayout :menu_id="(pageMode=='new')?'new':'attach'" :before_leave="before_leave" >
    <!-- <center style="color:red;"><H3>これは LAB 環境です。ここに実際のデータを追加しないでください。</H3></center> -->
    <div class="row content_div" >
      <div class="center_div">
        <h1 v-if="pageMode=='new'">
          {{ _d('new_page_title', '新しいアタッチメント', 'Page title that shows in the top of new attachment page') }}
        </h1>
        <h1 v-else >
          {{ _d('page_title', 'アタッチメント', 'Page title that shows in the top of attachment page') }}
        </h1>
        <div v-if="isLoaded">
          <div class="row" style="margin-top:20px">
            <div class="col-12" style="padding:10px 20px">
              <FileDropArea :attach_id="loadedData.id"
              :status="loadedData.attach_status"
              :disabled="loadedData.attach_status === attach_status.EXPIRED || loadedData.attach_status === attach_status.DELETED"
              :download_disabled="downloadStatusDisabled" />
            </div>
            <div class="col-12" style="padding:0px 10px 20px 10px">
              <div class="form-check">
              <input class="form-check-input" type="checkbox" id="allow_upload_checkbox"  v-model="loadedData.allow_upload" :disabled="loadedData.attach_status === attach_status.EXPIRED || loadedData.attach_status === attach_status.DELETED" >
              <label class="form-check-label" for="allow_upload_checkbox">
                {{ _d('chk_allow_upload', 'アップロードを許可する', 'Checkbox to ask to allow the upload or not, at the URl page.') }}
              </label>
              </div>
            </div>
            <div class="col-12" style="padding:0px 10px">
              <label class="sr-only" for="attach_key">{{ _d('url_label', 'URL: ', 'Url label at attach page.') }}</label>
              <div class="row">
                <div class="col-12" style="padding:0px 10px">
                  <div class="input-group mb-3" style="padding:0px">
                    <input type="text" class="form-control" id="attach_key" aria-describedby="btn-copy-url"
                    :value="loadedData.attach_url_prefix + loadedData.attach_key" autocomplete="off" data-lpignore="true"
                    style="max-width: 100%;float:left;" :disabled="true" data-cy="attach-key">
                    <button class="btn btn-primary" type="button" id="btn-copy-url"
                    style="border-radius:0rem 0.25rem 0.25rem 0rem;"
                    @click.prevent="onCopyUrl" data-cy="copy-url-button"
                    >{{ _d('copy_button_url', 'コピー', 'copy button at attach page')}}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12" style="padding:0px 10px;display: flex; flex-direction: column; justify-content: flex-end;" v-if="showPasswordRule === 'yes' || (pageMode=='new' && showPasswordRule === 'on_draft')">
              <label class="sr-only" for="attach_password"
                v-if="(pageMode=='new' && showPasswordRule === 'on_draft')"
                v-html="_d('password_label', 'パスワード: 「このパスワードを表示・保存するのはこの一回限りです。セキュリティ上の理由により、再表示されません。」', 'Password label at Draft attach page.')"></label>
              <label class="sr-only" for="attach_password"
                v-if="showPasswordRule === 'yes'"
                v-html="_d('all_times_show_password_label', 'パスワード:', 'Password label at attach page.To when to show the password all times is enabled')"></label>
              <div style="padding:0px;" >
                <div class="input-group " style="padding:0px;max-width: 300px;float:left;" >
                  <input type="text" class="form-control"
                    data-cy="attach-password"
                    id="attach_password" :disabled="loadedData.attach_status >= 0"
                    v-model="loadedData.attach_password" autocomplete="off"
                    data-lpignore="true"
                    aria-describedby="btn-copy-pass">
                  <button id="btn-copy-pass"
                    class="btn btn-primary btn-sm"
                    @click.prevent="onCopyPassword"
                    data-cy="copy-password-button"
                    >{{ _d('copy_button_password', 'コピー', 'copy button at attach page')}}</button>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-0"  v-if="showPasswordRule === 'yes' || (pageMode=='new' && showPasswordRule === 'on_draft')">  </div>
            <div class="col-lg-4 col-sm-12" style="padding:0px 10px;display: flex; flex-direction: column; justify-content: flex-end; align-items: flex-end;" v-if="showPasswordRule === 'yes' || (pageMode=='new' && showPasswordRule === 'on_draft')">
              <button class="btn btn-primary btn-large" style="margin: 12px 0px 0px 0px;width:100%"  @click.prevent="onCopyAll">{{ _d('copy_button_all', 'URLとパスワードをコピー', 'copy all data button at attach page')}}</button>
            </div>
            <div class="col-lg-4 col-sm-12" style="display: flex; flex-direction: column; justify-content: flex-end; padding:10px;">
              <label class="sr-only" for="attach_password" v-html="_d('status_label', 'ステータス:', 'In the form.')"></label>
              <select v-if="pageMode=='new'" class="form-select" id="attach_status" v-model="loadedData.attach_status"
                :disabled="statusDisabled" style="max-width: 1000px;" autocomplete="off" data-lpignore="true" data-cy="attach-status">
                <option v-for="(value, key) in attach_status"
                  :key="key"
                  :value="value"
                  :disabled=" value === attach_status.EXPIRED || value === attach_status.DRAFT || value === attach_status.PAUSE"
                  v-html="convertStatusLabel(value)"></option>
              </select>
              <select v-else class="form-select" id="attach_status" v-model="loadedData.attach_status"
                :disabled="statusDisabled || loadedData.attach_status === attach_status.EXPIRED" style="max-width: 1000px;" autocomplete="off" data-lpignore="true" data-cy="attach-status">
                <option
                :key="key"
                :disabled="loadedData.attach_status === attach_status.EXPIRED || value === attach_status.EXPIRED || value === attach_status.DRAFT"
                  v-for="(value, key) in attachStatusOptions" :value="value" v-html="convertStatusLabel(value)"></option>
              </select>
            </div>

            <div class="col-lg-4 col-sm-12" style="display: flex; flex-direction: column; justify-content: flex-end; padding:10px;">
              <label class="sr-only" for="attach_expire">{{ _d('expire_date_label', '有効期限:', 'Expire label at attach page')}}</label>
              <VueDatePicker id="attach_expire"
                v-model="attachExpire"
                :text-input="dateTextInputOptions()"
                locale="ja"
                :disabled="loadedData.attach_status === attach_status.EXPIRED || loadedData.attach_status === attach_status.DELETED"
                :format="attachExpireFormat" time-picker-inline :auto-apply="true" :clearable="false"
                :min-date="attach_expire_min_date" :max-date="attach_expire_max_date" :ignore-time-validation="false"
                :config="attach_expire_config" select-text="選択" cancel-text="キャンセル" style="max-width: 1000px;"
                autocomplete="off" data-lpignore="true" data-cy="attach-expire"  ></VueDatePicker>
            </div>
            <div class="col-lg-4 col-sm-12" style="display: flex; flex-direction: column; justify-content: flex-end; padding:10px;">
              <label class="sr-only" for="alias_name">{{ _d('attachment_name', 'アタッチ名: ', 'Attach name label at attach page')}}</label>
              <input type="text" class="form-control" id="alias_name" v-model="loadedData.alias_name"
                :disabled="loadedData.attach_status === attach_status.EXPIRED || loadedData.attach_status === attach_status.DELETED"
                style="max-width: 1000px;" autocomplete="off" data-lpignore="true" data-cy="alias-name">
            </div>
            <br>
            <div class="col-12" style="padding:10px">
              <MfaArea :attach_id="loadedData.id" @onChange="onMfaUpdate"  :mfa_required="loadedData.mfa_required" :mfa_address_list="loadedData.mfa_address_list"
                :allow_by_email="allowMfaByEmail"
                :allow_by_sms="allowMfaBySms"
                :disabled="loadedData.attach_status === attach_status.EXPIRED || loadedData.attach_status === attach_status.DELETED" />
            </div>

            <div class="col-lg-8 col-sm-0" style="padding:10px"></div>
            <div class="col-lg-4 col-sm-12" style="padding:10px;margin-bottom:40px;">
              <button class="btn btn-primary btn-large"
              style="float: right;width:100%;"
              data-cy="save-attach-button"
              @click.prevent="onSaveUpload(false)">{{ _d('save_button', '保存', 'save attachment button at NEW page') }}</button>
            </div>
          </div>
        </div>
        <div v-else-if="isError">
          <div class="alert alert-danger" role="alert">
            {{ _d('unexpected_error_message', '予期しないエラー', 'A non expected error message at attach page. it is not expected, so it should not be displayed.')}}
          </div>
        </div>
        <div v-else>
          <div class="spinner-border" role="status">
          </div>
          <span class="sr-only">{{ _d('loading_msg', 'Loading...', 'Loading message at attach page') }}</span>
        </div>
      </div>
    </div>
  </MainLayout>
  <ConfirmationModal ref="confirmationModalRef"></ConfirmationModal>
  <ConfirmationModal ref="confirmationModalRef2"></ConfirmationModal>
</template>
<script>

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import FileDropArea from '@/components/file-drop-area';
import ConfirmationModal from '@/components/modals/confirmation-modal';
import MfaArea from '@/components/mfa-area';
import MainLayout from '@/layouts/mainLayout';
import Dictionary from '@/mixins/dictionary';
import Settings from '@/mixins/settings';
import Nseq from 'nseq';
import moment from 'moment';

const ATTACH_STATUS = {
  DRAFT: 10,
  ACTIVE: 20,
  PAUSE: 30,
  DELETED: 40,
  EXPIRED: 50,
};
// const MFA_REQUIRED = {
//   NO: 0,
//   EMAIL: 1,
//   SMS: 2,
// };
export default {
  data() {
    return {
      dict_prefix: 'attach',
      attach_expire_config: {
        closeOnAutoApply: false,
      },
      attach_expire_min_date: new Date(),
      attach_expire_max_date: moment().add(1, 'month').toDate(),
      attach_status: ATTACH_STATUS,
      name: 'Attach',
      is_loaded: false,
      is_error: false,
      loaded_data: {},
      status_label: '',
      status_dialed: false,
      download_status_dialed: false,
      page_mode: 'none',
      confirmation_modal_config: {},
      show_password_rule: 'on_draft',
      allow_mfa_by_email: 'no',
      allow_mfa_by_sms: 'no',
    };
  },
  mixins: [Dictionary, Settings],
  routes: [
    {
      path: '/new/:key', name: 'New', component: this, meta: { requiresAuth: true, page_mode: 'new' },
    },
    {
      component: this,
      path: '/attach/:key',
      name: 'Attach',
      meta: { requiresAuth: true, page_mode: 'attach' },
    },
  ],
  computed: {
    downloadStatusDisabled: {
      get() {
        return this.download_status_dialed;
      },
      set(v) {
        this.download_status_dialed = v;
      },
    },
    attachExpire: {
      get() {
        return this.loadedData.attach_expire;
      },
      set(v) {
        console.log('TEST DEBUG 240521 (197 at attach.vue)[18:56]: ', { v });
        this.loadedData.attach_expire = v;
      },
    },
    allowMfaBySms: {
      get() {
        return this.allow_mfa_by_sms;
      },
      set(v) {
        this.allow_mfa_by_sms = v;
      },
    },
    allowMfaByEmail: {
      get() {
        return this.allow_mfa_by_email;
      },
      set(v) {
        this.allow_mfa_by_email = v;
      },
    },
    attachStatusOptions() {
      if (this.loadedData.attach_status === ATTACH_STATUS.EXPIRED) {
        return [
          // ATTACH_STATUS.DRAFT,
          // ATTACH_STATUS.ACTIVE,
          // ATTACH_STATUS.PAUSE,
          // ATTACH_STATUS.DELETED,
          ATTACH_STATUS.EXPIRED,
        ];
      }
      return [
        ATTACH_STATUS.DRAFT,
        ATTACH_STATUS.ACTIVE,
        ATTACH_STATUS.PAUSE,
        ATTACH_STATUS.DELETED,
        // ATTACH_STATUS.EXPIRED,
      ];
    },
    showPasswordRule: {
      get() {
        return this.show_password_rule;
      },
      set(v) {
        this.show_password_rule = v;
      },
    },
    pageMode: {
      get() {
        return this.page_mode;
      },
      set(v) {
        this.page_mode = v;
      },
    },
    statusDisabled: {
      get() {
        return this.status_dialed;
      },
      set(v) {
        this.status_dialed = v;
      },
    },
    statusLabel: {
      get() {
        return this.status_label;
      },
      set(v) {
        switch (v) {
          case ATTACH_STATUS.ACTIVE:
            this.status_label = this._gd('label_active', '活動中', 'Active label used in many places');
            break;
          case ATTACH_STATUS.DRAFT:
            this.status_label = this._gd('label_draft', '下書き', 'Draft  label used in many places');
            break;
          case ATTACH_STATUS.PAUSE:
            this.status_label = this._gd('label_pause', '一時停止', 'Pause  label used in many places');
            break;
          case ATTACH_STATUS.DELETED:
            this.status_label = this._gd('label_deleted', '削除された', 'Deleted  label used in many places');
            break;
          case ATTACH_STATUS.EXPIRED:
            this.status_label = this._gd('label_expired', '期限切れ', 'Expired  label used in many places');
            break;
          default:
            this.status_label = '---';
            break;
        }
      },
    },
    isError: {
      get() {
        return this.is_error;
      },
      set(v) {
        this.is_error = v;
      },
    },
    isLoaded: {
      get() {
        return this.is_loaded;
      },
      set(v) {
        this.is_loaded = v;
      },
    },
    loadedData: {
      get() {
        return this.loaded_data;
      },
      set(v) {
        this.loaded_data = v;
      },
    },
    displayName() {
      return localStorage.getItem('display_name');
    },
  },
  components: {
    FileDropArea, MainLayout, VueDatePicker, MfaArea, ConfirmationModal,
  },
  methods: {
    dateTextInputOptions() {
      return {
        format: (txt) => new Date(txt),
      };
    },
    onMfaUpdate(v) {
      this.loadedData.mfa_required = v.mfa_required;
      this.loadedData.mfa_address_list = v.address_list;
      if (v.address_mode === 0) {
        this.loadedData.mfa_address_list = '*';
      }
    },
    attachExpireFormat(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    convertStatusLabel(status) {
      switch (status) {
        case ATTACH_STATUS.ACTIVE:
          return this._gd('label_active', '活動中', 'Active label used in many places');
        case ATTACH_STATUS.DRAFT:
          return this._gd('label_draft', '下書き', 'Draft  label used in many places');
        case ATTACH_STATUS.PAUSE:
          return this._gd('label_pause', '一時停止', 'Pause  label used in many places');
        case ATTACH_STATUS.DELETED:
          return this._gd('label_deleted', '削除された', 'Deleted  label used in many places');
        case ATTACH_STATUS.EXPIRED:
          return this._gd('label_expired', '期限切れ', 'Expired  label used in many places');
        default:
          return this.status_label;
      }
    },
    onCopyUrl() {
      navigator.clipboard.writeText(this.loadedData.attach_url_prefix + this.loadedData.attach_key);
      this.$notify({ clean: true });
      this.$notify({
        duration: 1000,
        type: 'info',
        title: this._d('msg_url_was_copied', 'The url was copied', 'A message informing the URl was copied'),
      });
    },
    onCopyPassword() {
      navigator.clipboard.writeText(this.loadedData.attach_password);
      this.$notify({ clean: true });
      this.$notify({
        duration: 1000,
        type: 'info',
        title: this._d('msg_password_was_copied', 'The password was copied', 'A message informing the password was copied'),
      });
    },
    onCopyAll() {
      const obj = {
        url: this.loadedData.attach_url_prefix + this.loadedData.attach_key,
        password: this.loadedData.attach_password,
        alias_name: this.loadedData.alias_name,
        expire: moment(this.loadedData.attach_expire).format('YYYY-MM-DD HH:mm'),
      };
      const initial_text = 'URL: {{url}}\nパスワード: {{password}}\n';
      let text = this._d('all_data_copy_content', initial_text, 'The text the will be copied with copy all', obj);
      text = text.replace(/<br>/g, '\n');
      navigator.clipboard.writeText(text);
      this.$notify({ clean: true });
      this.$notify({
        duration: 1000,
        type: 'info',
        title: this._d('msg_all_was_copied', 'Data was copied', 'A message informing the url and password was copied'),
      });
    },
    onSaveUpload(cb = false) {
      console.log('TEST DEBUG 240212 (273 at attach.vue)[16:58]: ', { });
      let save_attach_status = this.loadedData.attach_status;
      (new Nseq()).do([
        (self) => {
          if (save_attach_status !== ATTACH_STATUS.DRAFT) {
            return self.next();
          }
          this.confirmation_modal_config = {
            title: this._gd('title_choose_how_to_save', 'URLを保存するステータスを選択してください', 'The title of dialog to choose to save as draft or active'),
            body: this._gd('message_choose_how_to_save', 'URL を下書きまたはアクティブとして保存しますか?<BR>下書き状態の間、受信者は URL にアクセスできません。', 'The message of dialog to choose to save as draft or active'),
            buttons: [
              {
                label: this._gd('btn_save_as_active_label', '活動中', 'At button to choose to save as active'),
                class: 'btn-primary',
                action: () => {
                  save_attach_status = ATTACH_STATUS.ACTIVE;
                  self.next();
                },
              },
              {
                label: this._gd('btn_save_as_daft_label', '下書き', 'At button to choose to save as draft'),
                class: 'btn-secondary',
                action: () => {
                  save_attach_status = ATTACH_STATUS.DRAFT;
                  self.next();
                },
              },
            ],
          };
          this.$refs.confirmationModalRef2.showModal(this.confirmation_modal_config);
        },
        (_self) => {
          const api_url = `/attach/save_new/${this.$route.params.key}`;
          const body = {
            attach_password: this.loadedData.attach_password,
            alias_name: this.loadedData.alias_name,
            attach_status: save_attach_status,
            mfa_required: this.loadedData.mfa_required,
            allow_upload: this.loadedData.allow_upload ? 1 : 0,
            mfa_address_list: this.loadedData.mfa_address_list,
            from_page: this.pageMode,
          };
          if (this.loadedData.attach_expire === null) {
            body.attach_expire = null;
          } else {
            body.attach_expire = moment(this.loadedData.attach_expire).format('YYYY-MM-DD HH:mm:59');
          }

          this.$ajax.post(api_url, body, (err, result, error_data) => {
            console.log('TEST DEBUG 240122 (286 at attach.vue)[19:41]: ', {
              err, result, body, error_data,
            });
            if (err) {
              if (error_data?.invalid_email_address === true) {
                this.$notify({
                  duration: 10000,
                  type: 'error',
                  title: this._d('invalid_email_format_error_popup_title', '無効な電子メール形式', 'in message to inform the title email is in invalid format.'),
                  text: error_data?.invalidEmails?.join(', '),
                });
              } else if (error_data?.invalid_phone_number === true) {
                this.$notify({
                  duration: 10000,
                  type: 'error',
                  title: this._d('invalid_sms_format_error_popup_title', '無効なSMS番号形式', 'in message to inform the title sms is in invalid format.'),
                  text: error_data?.invalidPhoneNumbers?.join(', '),
                });
              } else {
                this.$notify({
                  type: 'error',
                  title: this._d('unexpected_error_popup_title', '予期しないエラー', 'At attach URL page, a popup for an error title, to when save the attach.'),
                  text: err,
                });
              }
            } else {
              this.$notify({
                type: 'success',
                title: this._d('success_popup_title', 'Success', 'At attach URL page, a popup for an success title, to when save the attach.'),
                text: this._d('success_popup_text', 'Saved', 'At attach URL page, a popup for an success text, to when save the attach.'),
              });
              this.statusLabel = result.data.attach_status;
              this.loadedData.attach_status = result.data.attach_status;
              this.loadedData.attach_expire = result.data.attach_expire;
              this.loadedData.updated_time = result.data.updated_time;
              if (result.data.attach_status === this.attach_status.DELETED || result.data.attach_status === this.attach_status.EXPIRED) {
                this.statusDisabled = true;
              }

              if (result.data.attach_status === this.attach_status.EXPIRED && moment(result.data.attach_expire).add(7, 'day').isAfter(new Date())) { // TODO: Make this 7 days configurable
                this.downloadStatusDisabled = false;
              } else if (result.data.attach_status === this.attach_status.DELETED || result.data.attach_status === this.attach_status.EXPIRED) {
                this.downloadStatusDisabled = true;
              }
              if (cb) {
                return cb();
              }
            }
          });
        }]);
    },
    before_leave(cb) {
      if (this.loadedData.attach_status === this.attach_status.DRAFT && (this.loadedData?.updated_time || '') === '') {
        this.confirmation_modal_config = {
          title: this._d('leave_page_without_save_title_ask', '保存しますか?', 'Title to ask to leave the page without save'),
          body: this._d('leave_page_without_save_msg_ask', '保存しない場合、この現在のレコードはリストに表示されなくなり、削除されます。 保存しますか?', 'Msg to ask to leave the page without save'),
          buttons: [
            {
              label: this._d('leave_page_without_save_btn_confirm_save', 'はい、保存します', 'Button to confirm to save the the record before from leave'),
              class: 'btn-primary',
              action: () => {
                this.onSaveUpload(() => {
                  cb(true);
                });
              },
            },
            {
              label: this._d('leave_page_without_save_btn_confirm_leave', 'いいえ、保存しません', 'Button to confirm to NOT save the the record before from leave'),
              class: 'btn-secondary',
              action: () => {
                cb(true);
              },
            },
            {
              label: this._d('leave_page_without_save_btn_confirm_not_leave', 'いいえ、保存はしませんが、まだこのページにいます。', 'Button to confirm to NOT save the and not leave the page'),
              class: 'btn-info',
              action: () => {
                cb(false);
              },
            },
          ],
        };
        this.$refs.confirmationModalRef.showModal(this.confirmation_modal_config);
      } else {
        cb(true);
      }
    },
    // beforeUnload(event) {
    // if(this.loadedData.attach_status === this.attach_status.DRAFT && (this.loadedData?.attach_expire || "") === ""){
    //   event.preventDefault();
    //   event.returnValue =  "Leave the page without save?"
    // }
    // }
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
    this.pageMode = this.$route.meta.page_mode;
  },
  mounted() {
    this.loadSettings(['attach_show_password', 'allow_mfa_by_email', 'allow_mfa_by_sms'], (err, data) => {
      if (err) {
        this.$notify({
          type: 'error',
          title: 'Unexpected error to load settings',
          text: err,
        });
      } else {
        data.forEach((d) => {
          if (d.key_id === 'attach_show_password') {
            if (d.key_value === 'yes' || d.key_value === 'no' || d.key_value === 'on_draft') {
              this.showPasswordRule = d.key_value;
            }
          } else if (d.key_id === 'allow_mfa_by_email') {
            if (d.key_value === 'yes' || d.key_value === 'no') {
              this.allowMfaByEmail = d.key_value;
            }
          } else if (d.key_id === 'allow_mfa_by_sms') {
            if (d.key_value === 'yes' || d.key_value === 'no') {
              this.allowMfaBySms = d.key_value;
            }
          }
        });
      }
    });
    if (this.$route.params.key === 'undefined') {
      this.$notify({
        type: 'error',
        title: this._d('error_unexpected_at_attachment_mount', '予期しないエラー', 'The title of unexpected error'),
        text: this._d('error_invalid_url', 'URL が無効です', 'The text of invalid URL error'),
      });
      this.isError = true;
    } else if (this.$route.params.key === 'new') {
      // this block is no more required
      // Create a new attach at the API, and redirect the page to the new attach
      const api_url = '/attach/new';
      const body = {
        from_page: 'new',
      };
      this.$ajax.post(api_url, body, (err, result) => {
        console.log({ err, result, body });
        if (err) {
          this.$notify({
            type: 'error',
            title: this._d('error_unexpected_at_new_attachment_mount', '予期しないエラー', 'The title of unexpected error'),
            text: err,
          });
        } else {
          this.$router.push(`/new/${result.data.id}`);
          this.loadedData = result.data;
          this.loadedData.mfa_address_list = result.data.mfa_address_list || ''; // FIXME: It is temporary
          this.statusLabel = result.data.attach_status;
          this.isLoaded = true;
        }
      });
    } else {
      // Load the attach from the API
      const api_url = `/attach/get/${this.$route.params.key}`;
      const body = {
        from_page: this.pageMode,
      };
      this.$ajax.post(api_url, body, (err, result) => {
        console.log('TEST DEBUG 240122 (359 at attach.vue)[19:40]: ', { err, result, body });
        if (err) {
          this.$notify({
            type: 'error',
            title: this._d('error_unexpected_at_existing_attachment_mount', '予期しないエラー', 'The title of unexpected error'),
            text: err,
          });
        } else {
          // this.$router.push('/new/' + data.attach_id);
          this.loadedData = result.data;
          this.loadedData.allow_upload = result.data.allow_upload === 1;
          if (result.data.attach_status === this.attach_status.DELETED || result.data.attach_status === this.attach_status.EXPIRED) {
            this.statusDisabled = true;
          }
          if (result.data.attach_status === this.attach_status.EXPIRED && moment(result.data.attach_expire).add(7, 'day').isAfter(new Date())) { // TODO: Make this 7 days configurable
            this.downloadStatusDisabled = false;
          } else if (result.data.attach_status === this.attach_status.DELETED || result.data.attach_status === this.attach_status.EXPIRED) {
            this.downloadStatusDisabled = true;
          }
          this.statusLabel = result.data.attach_status;
          this.loadedData.mfa_address_list = result.data.mfa_address_list || ''; // FIXME: It is temporary
          this.isLoaded = true;
        }
      });
    }
    // window.addEventListener('beforeunload', this.beforeUnload);
    console.log('Attach mounted');
  },
  beforeUpdate() {
    console.log('Attach beforeUpdate');
  },
  updated() {
    console.log('Attach updated');
  },
  beforeUnmount() {
    console.log('Attach beforeUnmount');
  },
  unmounted() {
    console.log('Attach unmounted');
  },
  errorCaptured() {
    console.log('Attach errorCaptured');
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log('Attach renderTriggered');
  },
  activated() {
    console.log('Attach activated');
  },
  deactivated() {
    console.log('Attach deactivated');
  },

};
</script>
<style scoped>
.btn-large {
  margin: 10px 0px 10px 0px;
  width: 300px;
}

.btn-primary {
  background-color: #6666FF;
  /* Primary color */
  border-color: #5a62d2;
  /* Slightly darker shade for the border */
}

.btn-primary:hover {
  background-color: #6268e0;
  /* Slightly darker shade for hover */
  border-color: #4f58c2;
  /* Optionally, you could also darken the border color slightly on hover */
}
</style>

<style >
.dp__action_button.dp__action_cancel {
  width: 69px;
  font-size: 11px;
}

.dp__action_button.dp__action_select {
  width: 36px;
  font-size: 11px;
}

.content_div {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: calc(100vh - 250px);
  margin: 0px 10px;
  padding: 0px 100px;
  box-sizing: border-box;
}

.center_div {
  max-width: 1400px;
  margin:10px 10px;
}

@media (max-width: 1561px) {
  .content_div {
    padding: 0px 50px;
  }
  .center_div {
    min-width: 87vw;
  }
}
@media (max-width: 1200px) {
  .content_div {

    padding: 0px 10px;
  }
  .center_div {
    min-width: 95vw;
  }
}

</style>
