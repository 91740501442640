<template>
  <div>
    <div :class="'file-drop-area border p-4 text-center' + (highlighted > 0 ? ' highlighted' : '')"
        @dragover.prevent
        @dragenter.prevent="highlight"
        @dragleave.prevent="unHighlight"
        @drop.prevent="handleDrop"
        v-if="isDisabled === false"
        >
      <p v-html="_d('label_to_drop_here', 'ここにファイルをドラッグ＆ドロップします', 'Label on the drop area component')"></p>
      <p v-html="_d('label_to_drop_here_or', 'または', 'second Label on the drop area component')"></p>
      <input type="file" id="fileInput" ref="fileInput" multiple @change="handleFiles" style="display:none;" data-cy="file-input">
      <label for="fileInput" class="btn btn-primary" v-html="_d('button_select_file', 'ファイルの選択', 'Button to select the file to upload')" data-cy="select-file-button"></label>
    </div>
    <div v-else
      class="border p-4 text-center" style="height: 168px;display:none;">
    </div>
    <div class="row" style="margin-top:20px;font-size: 14px;">
      <table class="table table-striped table-hover table-sm">
        <thead>
          <tr v-if="uploadedFiles.length > 0">
            <th style="">{{_d('table_file_name', 'ファイル名', 'File name at list of files')}}</th>
            <th style="width: 100px;text-align: end;" > {{_d('table_file_size', 'サイズ', 'File size at list of files')}}</th>
            <th style="width: 110px;text-align: center;">{{_d('table_file_download_count', 'ダウンロード数', 'Download count at list of files')}}</th>
            <th style="width: 90px;text-align: center;" v-if="isSendingAndReceivingFiles === true">{{_d('table_file_direction', '方向', 'ファイルの方向を知らせるラベルです。同じ URL でファイルを送受信する場合にのみ表示されます。It is a Label to inform the direction of the file. It will only show when sending and receiving files at the same URL.')}}</th>
            <th style="width: 80px;text-align: end;" v-if="isDisabled === false">{{_d('table_delete_file', '削除', 'Label for button to delete files at at list of files')}}</th>
          </tr>
          </thead>
        <tr
          class="download_tr" style="font-size: 14px;border-bottom: solid 1px rgb(235, 234, 234);"
          v-for="file in uploadedFiles" :key="file.id">
          <td style="" v-if="file.file_status !== 9 && downloadStatusDisabled === false"
            @click.prevent="getRedirection(file)">
            <a href="#" style="color: #6666FF; background-color:#fff0; " :target="file.id">
            {{ fileNameFormat(file.file_display_name) }}
            </a>
          </td>
          <td style="" v-else >
            <span style="color: rgb(66 70 155); background-color:#fff0; ">
              {{ fileNameFormat(file.file_display_name) }}
            </span>
          </td>
          <td style="text-align: end;background-color:#fff0;"
            v-if="file.file_status !== 9 && downloadStatusDisabled === false"
            @click.prevent="getRedirection(file)">
            <a href="#" style="color: #6666FF; background-color:#fff0; " :target="file.id">
            {{ formatFileSize(file.file_size) }}
            </a>
          </td>
          <td style="text-align: end;background-color:#fff0;" v-else >
            <span style="color: rgb(66 70 155); background-color:#fff0; ">
            {{ formatFileSize(file.file_size) }}
            </span>
          </td>
          <td style="text-align: end;">
            <span v-if="file.log_count > 0 && file.owner_type === 1" style="background-color:#fff0;">
              <button @click.prevent="onOpenLog(file.id)" class="btn btn-flat btn-sm" style="padding-top: 1px; padding-bottom: 1px;background-color:#fff0;" data-cy="log-button">{{file.log_count}}</button>
            </span>
          </td>
          <td style="text-align: center;" v-if="isSendingAndReceivingFiles === true && file.owner_type === 1">{{_d('table_file_direction_sending', '送信', 'A Label to inform the direction of the file when is sending this file')}}</td>
          <td style="text-align: center;" v-else-if="isSendingAndReceivingFiles === true">{{_d('table_file_direction_receiving', '受信', 'A Label to inform the direction of the file is receiving this file')}}</td>
          <td style="text-align: end;" v-if="isDisabled === false">
            <span v-if="file.file_status === 9" class="btn btn-flat btn-sm" :disabled="isDisabled" style="color:#9f131391;padding-top: 1px; padding-bottom: 1px;background-color:#fff0;" data-cy="delete-file-button">{{_d('table_file_was_expired_and_deleted', '削除済み', 'The file was already deleted as it is expired')}}</span>
            <button v-else-if="file.owner_type === 1" @click.prevent="onDeleteFile(file.id)" class="btn btn-flat btn-sm" :disabled="isDisabled" style="color:red;padding-top: 1px; padding-bottom: 1px;background-color:#fff0;" data-cy="delete-file-button"><i class="bi bi-trash"></i></button>
          </td>
        </tr>
      </table>

    </div>
    <div class="progress"
      style="margin-top: 10px;"
      v-if="uploadProgress > 0 && uploadProgressBars.length > 0"
      v-for="pb in uploadProgressBars"
      :key="pb.id">
      <div

      class="progress-bar progress-bar-striped progress-bar-animated"
      role="progressbar"
      :aria-valuenow="pb.progress"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="'width: ' + pb.progress +'%'">{{ pb.label }}</div> <span v-if='pb.progress < 1' style="margin-left:10px;">{{ pb.label }}</span>
    </div>
    <transition name="fade">
      <div v-if="showStopUpload" style="display: flex; justify-content: center; align-items: center;">
        <button  class="btn btn-danger" style="margin-top: 10px;    min-width: 300px;" data-cy="stop-upload-button" @click.prevent="onStopUploads()" >{{_d('btn_stop_upload', 'アップロードを停止する', 'The button to stop de uploads')}}</button>
      </div>
    </transition>
    <downloadLogModal ref="downloadLogModal"></downloadLogModal>
    <ConfirmationModal ref="confirmationModalRef"></ConfirmationModal>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import downloadLogModal from '@/components/modals/download-log-modal';
import Dictionary from '@/mixins/dictionary';
import ConfirmationModal from '@/components/modals/confirmation-modal';

export default {
  data() {
    return {
      dict_prefix: 'file_drop_area',
      files: [],
      uploaded_files: [],
      upload_progress: 1,
      upload_progress_bars: [],
      storage_id: 2,
      is_uploading: false,
      highlighted: 0,
      show_stop_upload: false,
      force_stop_upload: false,
      uploading_counter: 0,
      upload_queue: [],
      is_sending_and_receiving_files: false,
    };
  },
  mixins: [Dictionary],
  components: {
    downloadLogModal, ConfirmationModal,
  },
  computed: {
    uploadingCounter: {
      get() {
        return this.uploading_counter;
      },
      set(v) {
        this.uploading_counter = v;
      },
    },
    forceStopUpload: {
      get() {
        return this.force_stop_upload;
      },
      set(v) {
        this.force_stop_upload = v;
      },
    },
    showStopUpload: {
      get() {
        return this.show_stop_upload;
      },
      set(v) {
        this.show_stop_upload = v;
      },
    },
    isDisabled() {
      return this.$props.disabled;
    },
    downloadStatusDisabled() {
      return this.$props.download_disabled;
    },
    attachStatus() {
      return this.$props.status;
    },
    isUploading: {
      get() {
        return this.is_uploading;
      },
      set(v) {
        this.is_uploading = v;
      },
    },
    storageID: {
      get() {
        return this.storage_id;
      },
      set(v) {
        this.storage_id = v;
      },
    },
    uploadProgressBars: {
      get() {
        return this.upload_progress_bars;
      },
      set(v) {
        this.upload_progress_bars = v;
      },
    },
    uploadProgress: {
      get() {
        return this.upload_progress;
      },
      set(v) {
        this.upload_progress = v;
      },
    },
    isSendingAndReceivingFiles: {
      get() {
        return this.is_sending_and_receiving_files;
      },
      set(v) {
        this.is_sending_and_receiving_files = v;
      },
    },
    uploadedFiles: {
      get() {
        return this.uploaded_files;
      },
      set(v) {
        let temp = false;
        if (v && v.length > 0) {
          v.forEach((file) => {
            if (file.owner_type !== 1) {
              temp = true;
            }
          });
        }
        this.isSendingAndReceivingFiles = temp;
        this.uploaded_files = v;
      },
    },
  },
  props: ['attach_id', 'disabled', 'status', 'download_disabled'],
  mounted() {
    if (this.attach_id !== null && this.attach_id !== 'new' && this.attach_id !== 'undefined') {
      const api_url = `/attach/files/${this.attach_id}`;
      const body = {};
      this.$ajax.post(api_url, body, (err, result) => {
        console.log({ err, result, body });
        console.log('TEST DEBUG 231115 (102 at file -drop -area.vue)[21:38]: ', { });
        if (err) {
          this.$notify({
            type: 'error',
            title: this._d('unexpected_error_message_at_load_data', '予期しないエラー', 'An error message to an unexpected error'),
            text: err,
          });
        } else {
          this.uploadedFiles = result;
        }
      });
    }
  },
  methods: {
    getRedirection(file) {
      if (file.file_status === 9) {
        return;
      }
      if (this.statusDisabled === true) {
        return;
      }
      console.log('TEST DEBUG 240121 (243 at upload_link.vue)[22:14]: ', { file });
      const api_url = `/attach/bl/${this.$route.params.key}/${file.id}`; // FIXME:
      const body = {
      };
      this.$ajax.post(api_url, body, (err, result) => {
        if (err) {
          this.$notify({
            type: 'error',
            title: this._d('upload_link_unexpected_error_popup_title', '予期しないエラー', 'At get download a file from upload link, a popup for an unexpected error title.'),
            text: err,
          });
          return;
        }
        window.open(result.url, '_blank');
      });
    },
    onStopUploads() {
      this.confirmation_modal_config = {
        title: this._d('title_to_confirm_to_stop_upload', '停止を確認する', 'An  title to confirm to stop the upload'),
        body: this._d('body_to_confirm_to_stop_upload', 'アップロードを停止してもよろしいですか?', 'An  message to confirm to stop the upload'),
        buttons: [
          {
            label: this._d('btn_to_confirm_to_stop_upload', 'はい', 'An  button to confirm to stop the upload'),
            class: 'btn-danger',
            action: () => {
              this.forceStopUpload = true;
              this.showStopUpload = false;
              this.$notify({
                type: 'info',
                title: this._d('uploads_stopped_notification', 'アップロードは停止されました', 'An  message to inform that uploads stopped'),
              });
            },
          },
          {
            label: this._d('btn_to_cancel_to_stop_upload', 'いいえ', 'An  button to cancel to stop the upload'),
            class: 'btn-info',
            action: () => { },
          },
        ],
      };
      this.$refs.confirmationModalRef.showModal(this.confirmation_modal_config);
    },
    onOpenLog(id) {
      this.$refs.downloadLogModal.showModal({
        attach_id: this.attach_id,
        file_id: id,
        on_confirm: (answer) => {
          console.log('on_confirm', { answer });
        },
      });
    },
    onDeleteFile(id) {
      if (this.isUploading === true) {
        this.$notify({
          type: 'error',
          title: 'Uploading',
          text: this._d('error_message_asking_to_wait_upload_to_complete', 'アップロードが完了するまでお待ちください。', 'An error message while uploading a file, and try to delete another file'),
        });

        return;
      }
      const api_url = `/attach/delete/${this.attach_id}/${id}`;
      const body = {};
      this.$ajax.post(api_url, body, (err, result) => {
        console.log({ err, result, body });
        if (err) {
          this.$notify({
            type: 'error',
            title: this._gd('unexpected_error_message', '予期しないエラー', 'An error message to an unexpected error'),
            text: err,
          });
        } else {
          this.uploadedFiles = result;
        }
      });
    },
    formatFileSize(size_in_bytes) {
      // If the file is bigger then 1MB then show the size in MB
      if (size_in_bytes > 1000000) {
        return `${(size_in_bytes / 1000000).toFixed(1)} MB`;
      }
      return `${(size_in_bytes / 1000).toFixed(1)} KB`;
    },
    storageIDFormat(storage_id) {
      const style = 'badge bg-primary';
      if (storage_id === 2) {
        return `<span class='${style}'>S3</span>`;
      } if (storage_id === 3) {
        return `<span class='${style}'>Vultr</span>`;
      } if (storage_id === 4) {
        return `<span class='${style}'>R2</span>`;
      }
      return `<span class='${style}'>UNKNOWN</span>`;
    },
    fileNameFormat(name) {
      // if file name is bigger then 30 chars then cut showing the first 10 chars and the last 10 chars
      if (name.length > 75) {
        return `${name.substring(0, 35)}...${name.substring(name.length - 35, name.length)}`;
      }
      return name;
    },
    highlight() {
      this.highlighted += 1;
    },
    unHighlight() {
      this.highlighted -= 1;
    },
    handleDrop(event) {
      this.highlighted -= 1;
      const temp_files = [...event.dataTransfer.files];
      if (this.validateFiles(temp_files)) {
        this.prepareUploadFiles(temp_files);
      }
    },
    handleFiles(event) {
      const temp_files = [...event.target.files];
      if (this.validateFiles(temp_files)) {
        this.prepareUploadFiles(temp_files);
      }
    },
    validateFiles(files) {
      const removed = _.remove(files, (file) => file.size > 10737418240);
      if (removed.length > 0) {
        this.$notify({
          duration: 10000,
          type: 'error',
          text: `${this._d('file_is_too_big_error_message_at_upload_data', 'ファイル サイズが大きすぎます。1 ファイルあたり 10GB が制限です。', 'An error message to inform file is too big')}`,
        });
        return false;
      }
      return true;
    },

    prepareUploadFiles(temp_files) {
      const base_time = new Date().getTime();
      this.$notify({ clean: true });
      const file_length = temp_files.length;

      this.forceStopUpload = false;
      this.showStopUpload = true;
      for (let i = 0; i < file_length; i += 1) {
        const up_progress = {
          seq: `${base_time}_ ${i}`,
          id: `${i}_${base_time}_${temp_files[i].name}`,
          progress: 0,
          file_name: temp_files[i].name,
          label: `${temp_files[i].name}${this._d('upload_progress_bar_waiting_message', ' 待機中…', 'The message in the progress ber while it is waiting in the queue to be uploaded.')}`, // FIXME:, set it to dictionary
        };
        this.uploadingCounter += 1;
        this.uploadProgressBars.push(up_progress);
        this.upload_queue.push({ file: temp_files[i], seq: up_progress.seq });
        this.uploadFiles();
      }
    },
    async uploadFiles() {
      if (this.isUploading === true) {
        return;
      }
      this.forceStopUpload = false;
      this.showStopUpload = true;
      this.isUploading = true;

      while (this.upload_queue.length > 0) {
        const obj = this.upload_queue.shift();
        if (this.forceStopUpload === false) {
          const up_progress = _.find(this.uploadProgressBars, (o) => o.seq === obj.seq);
          await this.uploadOneFile(obj.file, up_progress);
          this.uploadingCounter -= 1;
        } else {
          this.files = [];
          this.upload_queue = [];
          this.uploadProgressBars = [];
          this.uploadingCounter = 0;
        }
      }
      this.isUploading = false;
      this.uploadProgress = -1;
      if (this.uploadProgress < 1 && this.uploadingCounter < 1) {
        this.uploadingCounter = 0;
        this.showStopUpload = false;
      }
    },
    async uploadOneFile(file, up_progress) {
      // Fist create a upload id at the server.
      const start_time = new Date().getTime();
      const cancel_token_source = axios.CancelToken.source();
      let upload_id_data = null;
      try {
        upload_id_data = await this.$ajax.post_async('/attach/create_upload_id', {
          attach_id: this.attach_id,
          file_name: file.name,
          file_size: file.size,
          storage_id: this.storage_id,
        });
      } catch (error) {
        if (error?.message?.indexOf('FILE_IS_TOO_BIG') > -1) {
          this.$notify({
            duration: 10000,
            type: 'error',
            title: this._d('file_is_too_big_error_message_at_upload_data', 'ファイル サイズが大きすぎます。1 ファイルあたり 10GB が制限です。', 'An error message to inform file is too big'),
          });
        } else {
          this.$notify({
            duration: 10000,
            type: 'error',
            title: this._d('unexpected_error_message_at_upload_data', '予期しないエラー', 'An error message to an unexpected error'),
            text: error,
          });
        }
        return;
      }

      // Second will create a chunks, and must be upload one by one.
      let chunkSize = 64 * 1024 * 1024; // 64MB

      if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        chunkSize = 6 * 1024 * 1024; // 6MB
      }
      console.log('TEST DEBUG 231112 (174 at file -drop -area.vue)[00:24]: ', { chunkSize });

      const totalChunks = Math.ceil(file.size / chunkSize);

      let pb_total_loaded = 0;
      const pb_total_expected = file.size;
      let pb_chunk_loaded = 0;

      for (let index = 0; index < totalChunks; index += 1) {
        pb_total_loaded += pb_chunk_loaded;
        pb_chunk_loaded = 0;
        const chunk = file.slice(
          index * chunkSize,
          (index + 1) * chunkSize,
        );
        if (this.forceStopUpload) {
          cancel_token_source.cancel('Upload cancelled by the user.');
          break;
        }

        const put_presigned_url = await this.$ajax.post_async('/attach/get_upload_multipart_presigned_url', {
          upload_id: upload_id_data.upload_id,
          chunk_index: index,
        });
        try {
          const upload_response = await axios.put(put_presigned_url.url, chunk, {
            cancelToken: cancel_token_source.token,
            onUploadProgress: (progressEvent) => {
              pb_chunk_loaded = progressEvent.loaded;
              const progress = Math.round(((pb_total_loaded + pb_chunk_loaded) * 100) / pb_total_expected);
              if (this.forceStopUpload) {
                cancel_token_source.cancel('Upload cancelled by the user.');
              }
              if (progress > up_progress.progress) {
                up_progress.progress = progress;
                this.uploadProgress = progress;
                if (progress < 98 || totalChunks < 10) {
                  up_progress.label = `${progress}%`;
                } else if (progress < 100) {
                  up_progress.label = `${98}%`;
                } else {
                  this.uploadProgress = 98;
                  up_progress.label = this._d('progress_bar_message_processing', '処理中….', 'At the progress bar a message to inform it is processing the file at the server');
                }
              }
            },
          });
          console.log(`Chunk ${index + 1} of ${totalChunks} uploaded.`);

          const { etag } = upload_response.headers;

          await this.$ajax.post_async('/attach/register_etag', {
            upload_id: upload_id_data.upload_id,
            chunk_index: index,
            etag,
          });
        } catch (error) {
          this.isUploading = false;
          if (axios.isCancel(error)) {
            console.log('Upload was cancelled by the user.');
          } else {
            console.error('Error uploading chunk: ', error);
          }
          break;
        }
      }
      if (this.forceStopUpload) {
        _.remove(this.uploadProgressBars, (n) => n.id === up_progress.id);
      } else {
        up_progress.label = 'processing file at server...';
        const end_time = new Date().getTime();
        const elapsed_time = end_time - start_time;
        const result = await this.$ajax.post_async('/attach/complete_upload', {
          attach_id: this.attach_id,
          file_name: file.name,
          file_size: file.size,
          total_chunks: totalChunks,
          file_mimetype: file.mimetype || file.type || 'application/octet-stream',
          upload_id: upload_id_data.upload_id,
          storage_id: this.storage_id,
          upload_info: JSON.stringify({ elapsed_time }),
        });
        this.uploadedFiles = result;

        _.remove(this.uploadProgressBars, (n) => n.id === up_progress.id);
      }
    },
  },
};
</script>

<style scoped>
.file-drop-area {
  cursor: pointer;
  transition: background-color 0.2s;
}
.file-drop-area:hover {
  background-color: #f7f7f7;
}
.file-drop-area.dragover {
  background-color: #e9ecef;
}
.highlighted {
  background-color: #d4d6ff;
  border : 3px dashed #6666FF!important;
}
.download_tr:hover{
  background-color:#f7f7f7;
}
.btn-primary {
    background-color: #6666FF; /* Primary color */
    border-color: #5a62d2; /* Slightly darker shade for the border */
}

.btn-primary:hover {
    background-color: #6268e0; /* Slightly darker shade for hover */
    border-color: #4f58c2; /* Optionally, you could also darken the border color slightly on hover */
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
